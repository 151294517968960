import React from "react"
import { Menu as AntMenu } from "antd"
import { Link, navigate } from "gatsby"
import Logo from "./../../static/img/logo.png"
import LogoBlack from "./../../static/img/logo_black.png"
import { useLocation } from "@reach/router"

const Menu = () => {
  const { SubMenu } = AntMenu
  const location = useLocation()
  const toggleMobileMenu = () =>
  {
    document.getElementById("mobile-menu").classList.toggle("hidden")
  }
  return (
    <div>
      <div className="flex justify-between items-center p-5 px-20 menu-fixed"
           style={{ maxWidth: "1600px", margin: "auto" }}>
        <div className="">
          <a onClick={() => navigate(`/`)}> <img src={Logo} width="200px" className="menu-logo" alt="logo" /></a>
        </div>
        <div>
          <div className="lg:hidden white-text">
            <AntMenu mode="horizontal">
              <SubMenu
                className="link-white"
                key="SubMenu"
                title={<i className="fas fa-bars" style={{
                  fontSize: "25px",
                  position: "relative",
                  top: "5px"
                }} onClick={() => toggleMobileMenu()}></i>}
              ></SubMenu>
            </AntMenu>
          </div>
          <div className="hidden lg:inline">
            <AntMenu mode="horizontal">
              <AntMenu.Item key="pricing" className="link-white">
              <span
                onClick={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                Pricing
              </span>
              </AntMenu.Item>
              <AntMenu.Item key="features" className="link-white">
              <span
                onClick={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                Features
              </span>
              </AntMenu.Item>

              <AntMenu.Item key="app" className="link-white">
                <span
                  onClick={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  onKeyDown={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  role="button"
                  tabIndex={0}
                >
                  FAQ
                </span>
              </AntMenu.Item>

              <AntMenu.Item key="contact">
                <Link className="link-white" to="/contact">
                  Contact
                </Link>
              </AntMenu.Item>
            </AntMenu>
          </div>
        </div>

      </div>
      <div className="menu-mobile flex justify-between hidden" id="mobile-menu" style={{ paddingRight: "1.75rem", paddingLeft: "1.75rem" }}>
        <div className="">
          <a onClick={() => navigate(`/`)}> <img src={LogoBlack} width="200px" className="menu-logo" alt="logo" /></a>
        </div>
        <div>
          <i className="fas fa-plus close-menu-mobile" style={{
            fontSize: "30px",
            position: "relative",
            top: "8px",
            cursor: "pointer"
          }} onClick={() => toggleMobileMenu()}></i>
        </div>
        <AntMenu mode="vertical">
          <AntMenu.Item key="pricing">
              <span
                onClick={() => {
                  toggleMobileMenu();
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                <i className="fa fa-money-bill"></i> Pricing
              </span>
          </AntMenu.Item>
          <AntMenu.Item key="features">
              <span
                onClick={() => {
                  toggleMobileMenu();
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                <i className="fa fa-tools"></i> Features
              </span>
          </AntMenu.Item>

          <AntMenu.Item key="app">
                <span
                  onClick={() => {
                    toggleMobileMenu();
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  onKeyDown={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <i className="fa fa-question"></i> FAQ
                </span>
          </AntMenu.Item>

          <AntMenu.Item key="contact">
            <Link to="/contact">
              <i className="fa fa-envelope"></i> Contact
            </Link>
          </AntMenu.Item>
        </AntMenu>
      </div>
    </div>
  )
}

export default Menu
